import React from 'react'
import styles from './AboutUs.module.scss'
import image from '../../image/AboutUs/img_1.png'

export const AboutUs = () => {

    return (
        <section className={styles.main} id='AboutUs'>
            <div className={styles.container}>
                    <img className={styles.image} src={image} alt='мастер'/>
                    <div className={styles.box_info}>
                        <h2 className={styles.title}>О компании<span> SepticDoctor</span></h2>
                        <p className={styles.text}>&#8195;Специалисты SepticDoctor профессионально оказывают услуги по обслуживанию и ремонту автономных канализаций в Москве и Московской области.</p>
                        <p className={styles.text}>&#8195;Нами накоплен колоссальный опыт в сфере сервиса автономных канализаций , что позволяет предоставить нашим клиентам высокий уровень сервиса.</p>
                        <p className={styles.text}>&#8195;Мы производим обслуживание и ремонтируем все модели септиков. Работаем согласно требованиям производителей станций. Приезжаем со всем необходимым инструментом и комплектующими для обслуживания и ремонта локально-очистных сооружений. Быстро и эффективно решаем проблему с автономной канализацией.</p>
                        <p className={styles.text}>&#8195;Мы гарантируем высокое качество работы и привлекательные цены на все наши услуги.</p>
                    </div>
            </div>
        </section>
        
        
    )
}