import React from 'react'
import styles from './Deadlines.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Deadlines/img_1.svg'
import image_2 from '../../image/Deadlines/img_2.svg'
import image_3 from '../../image/Deadlines/img_4.svg'
import image_4 from '../../image/Deadlines/img_5.svg'
import image_5 from '../../image/Deadlines/img_3.svg'
import image_6 from '../../image/Deadlines/img_6.svg'

export const Deadlines = ({isOpen}) => {

    const titleAnimation = {
        hidden: {
            y: 200,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .3 }
        }
    }
    
    const boxAnimation = {
        hidden: {
            y: 500,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .6}
        } 
    }

    return (
        <motion.section 
            className={styles.main} 
            id='Deadlines'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
            >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Порядок<span> обслуживания</span></motion.h2>
            <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_1} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Заявка</h3>
                            <p className={styles.item_text}>Вы оставляете электронную заявку на сайте, электронной почте или звоните нам по одному из телефонных номеров.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_2} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Консультация</h3>
                            <p className={styles.item_text}>Уточняем модель и особенности вашего септика, имеющиеся неисправности, определяем время и стоимость работ</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_3} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Выезд</h3>
                            <p className={styles.item_text}>В назначенные дату и время мы приезжаем на объект со всем необходимым оборудованием и запчастями.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_4} alt='инструмент'/>
                            </div>
                            <h3 className={styles.item_title}>Работы</h3>
                            <p className={styles.item_text}>Наши мастера проводят все оговоренные работы и производят замену деталей (при необходимости).</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_5} alt='шестиренка'/>
                            </div>
                            <h3 className={styles.item_title}>Тестирование</h3>
                            <p className={styles.item_text}>После окончания работ мастера  проводят тестирование оборудования, чтобы убедиться в его правильной работе.</p>
                        </li>
                        <li className={styles.item}>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_6} alt='кошелек'/>
                            </div>
                            <h3 className={styles.item_title}>Оплата</h3>
                            <p className={styles.item_text}>Вы оплачиваете стоимость работ и деталей. Мы предоставляем гарантию на проведенные работы и замененные детали.</p>
                        </li>
                    </motion.ul>
                    <button className={styles.button} type='button' onClick={isOpen}>вызвать мастера</button>
            </div>   
        </motion.section>
        
        
    )
}
