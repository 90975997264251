import React, {useState, useCallback} from 'react'
import styles from './Works.module.scss'
import { motion } from "framer-motion"
import { v4 as uuidv4 } from 'uuid'
import { arrWorks } from '../../utils/data'
import { CardImage } from '../CardImage/CardImage'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .6}
    } 
}

export const Works = ({isImageOpen}) => {

    const [worksTotal, setWorksTotal] = useState(3)

    const addCards = useCallback(() => {
        setWorksTotal(worksTotal + 1);
    }, [worksTotal])


    return (
        <motion.section 
            className={styles.main} 
            id='Works'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
            >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Примеры<span> наших работ</span></motion.h2>
            <motion.ul className={styles.list} variants={boxAnimation}>
                        {arrWorks.map((item, index) => {
                        const keyUid = uuidv4()
                            if (index + 1 <= worksTotal) {
                                return(
                                    <CardImage
                                        key={keyUid}
                                        item={item}
                                        index={index}
                                        isImageOpen={isImageOpen} 
                                    />
                                )
                            } else {
                                return ''
                            }
                    })}         
                    </motion.ul>
                    {worksTotal < arrWorks.length && <button  className={styles.button} onClick={addCards}>Ещё работы</button>}
            </div>   
        </motion.section>
        
        
    )
}