import React from 'react'
import styles from './MenuMobile.module.scss'
import logo from '../../image/Header/logo.svg'
import mail from '../../image/Footer/email-icon.svg'
import phone from '../../image/Footer/phone.svg'

export const MenuMobile = ({onClose}) => {

    return (
        <div className={styles.main}>
            <div className={styles.box}>
                <img className={styles.logo} src={logo} alt = 'логотип'/>
                <button className={styles.button} onClick={onClose} aria-label='закрыть меню'/>
            </div>
            <nav className={styles.nav}>
                    <a className={styles.link} href='#AboutUs' onClick={onClose}>О нас</a>
                    <a className={styles.link} href='#Services' onClick={onClose}>Услуги</a>
                    <a className={styles.link} href='#Works' onClick={onClose}>Работы</a>
                    <a className={styles.link} href='#Prices' onClick={onClose}>Цены</a>
            </nav>
            <div className={styles.box_info}>
                    <div className={styles.box_mail}>
                        <img className={styles.icon_mail} src={phone} alt = 'icon'/>
                        <a className={styles.link_mail}  href="tel:+79055456623" target='_blank' rel='noopener noreferrer'>+7 (905) 545-66-23</a>
                    </div>
                    <div className={styles.box_mail}>
                        <img className={styles.icon_mail} src={mail} alt = 'icon'/>
                        <a className={styles.link_mail}  href="mailto:service.septiks@yandex.ru" target='_blank' rel='noopener noreferrer'>service.septiks@yandex.ru</a>
                    </div>
                </div>
        </div>
    );
}
