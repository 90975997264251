import React from 'react'
import styles from './ServiceOptions.module.scss'
import { motion } from "framer-motion"



const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}


export const ServiceOptions = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} id='Portfolio'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.2, once: true}}
        >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Варианты обслуживания <span>септика</span></motion.h2>
                <motion.div className={styles.box_info} variants={boxAnimation}>
                    <div className={styles.item} >
                        <h3 className={styles.subtitle}>Разовое обслуживание септика</h3>
                        <p className={styles.text}>После оставления заявки менеджер обсудит перечень необходимых работ по обслуживанию, согласует удобную для вас дату и время. В назначенный день приедет наш сервисный специалист и выполнит работу.</p>
                        <button className={styles.button} type='button' onClick={isOpen}>заказать</button>
                    </div>
                
                    <div className={styles.item}>
                        <h3 className={styles.subtitle}>Годовое обслуживание септика</h3>
                        <p className={styles.text}>Услуга предназначена для тех, кто постоянно проживает в доме. Заключается договор на обслуживание и несколько раз в год приезжаем для обслуживания вашей автономной канализации. Поддерживаем работу системы и в случае аварийной ситуации приезжаем незамедлительно.</p>
                        <button className={styles.button} type='button' onClick={isOpen}>заказать</button>
                    </div>
                </motion.div>
            
            </div>
        </motion.section>
    )
}