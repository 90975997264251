import React, { useState } from 'react';
import styles from './Hint.module.scss'
import disactive from '../../image/Hint/disactive.svg'
import { v4 as uuidv4 } from 'uuid'
import { arrServices } from '../../utils/data';

export  const Hint = ({question, index}) => {

    const [isActive, setIsActive] = useState(false);


    return (
        <li className={styles.hint}>
            <div className={styles.box} onClick={() => setIsActive(!isActive)}>
                <div className={styles.hint_box}>
                    <div className={styles.hint_circle}> </div>
                    <h3 className={styles.title}>{question}</h3>
                </div>
                <img className={!isActive ? styles.hint_image : styles.hint_active} src={disactive} alt='галочка'  onClick={() => setIsActive(!isActive)}/>
            </div>
            {isActive && (
            <div className={styles.box_active}>
                {arrServices[index].map((item, index)  => {
                    const keyUid = uuidv4();
                    if(index % 2 === 0){
                        return(
                            <div className={styles.cap} key={keyUid}>
                                <p className={styles.service}>{item.title}</p>
                                <p className={styles.price}>{item.price}</p>
                            </div>
                            )
                    } else {
                        return(
                            <div className={styles.cap_grey} key={keyUid}>
                                <p className={styles.service}>{item.title}</p>
                                <p className={styles.price}>{item.price}</p>
                            </div>
                        )
                    }
                    
                }   
                    )}  
                            </div>
                            )}
        </li>
    )
}