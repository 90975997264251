import React, {useState} from 'react'
import header from './Header.module.scss'
import logo from '../../image/Header/logo.svg'

export const Header = ({isOpen}) => {

    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 10){
            setVisible(true)
        } 
        else if (scrolled <= 10){
            setVisible(false)
        }
    }

    window.addEventListener('scroll', toggleVisible);

    return (
    <header  className={`${header.header} ${visible && header.header_scroll}`}>
            <div className={header.box}>               
                    <img className={header.logo} src={logo} alt = 'логотип'/>
                <nav className={header.nav}>
                    <a className={header.link} href='#AboutUs'>О нас</a>
                    <a className={header.link} href='#Services'>Услуги</a>
                    <a className={header.link} href='#Works'>Работы</a>
                    <a className={header.link} href='#Prices'>Цены</a>
                </nav>
                <div className={header.box_phone}>
                    <a className={header.link_phone}  href="tel:+79055456623" target='_blank' rel='noopener noreferrer'>+7 (905) 545-66-23</a>
                </div>
                
                <button className={header.button} type='button' onClick={isOpen} aria-label='открыть меню'/>
            </div>         
    </header>
    );
};