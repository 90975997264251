import React from 'react'
import { motion } from "framer-motion"
import styles from './Prices.module.scss'
import { Hint } from '../Hint/Hint'
import { arrQuestions } from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}


export const Prices = () => {

    return (
        <motion.section
            id='Prices'
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.2, once: true}}
            >
                    <div className={styles.box}>
                        <div className={styles.container}>
                            <motion.h2 className={styles.title} variants={titleAnimation}>Порядок цен на<span> наши услуги</span></motion.h2>
                            <motion.ul className={styles.list} variants={boxAnimation}>
                                    {arrQuestions.map((item, index ) => {
                                        const keyUid = uuidv4();
                                        return(
                                            <Hint
                                                question={item.question}
                                                index={index}
                                                key={keyUid}
                                        />
                                        )
                                    }   
                                        )}
                            </motion.ul>
                        </div>
                    </div>
        </motion.section>
        
    )
}