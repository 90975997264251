import React from 'react'
import styles from './Main.module.scss'
import { InitialPage } from '../../components/initialPage/InitialPage'
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'
import { AboutUs } from '../../components/AboutUs/AboutUs'
import { OurServices } from '../../components/OurServices/OurServices'
import { ServiceOptions } from '../../components/ServiceOptions/ServiceOptions'
import { Deadlines } from '../../components/Deadlines/Deadlines'
import { SliderSeptic } from '../../components/SliderSeptic/SliderSeptic'
import { Prices } from '../../components/Prices/Prices'
import { ModelSeptic } from '../../components/ModelSeptic/ModelSeptic'
import {Discount} from '../../components/Discount/Discount'
import { Works } from '../../components/Works/Works'

export const Main = ({isOpen, isImageOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <SliderAdvantages/>
            <AboutUs
                isOpen={isOpen}
            />
            <SliderSeptic/>
            <OurServices
                isOpen={isOpen}
            />
            <ServiceOptions
                isOpen={isOpen}
            />
            <SliderSeptic/>
            <Works/>
            <ModelSeptic/>
            <Prices/>
            <Discount/>
            <SliderAdvantages/>
            <Deadlines
                isOpen={isOpen}
            />
        </section>
    )
}