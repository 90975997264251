import img_1 from '../image/Works/img_1_1.webp'
import img_2 from '../image/Works/img_1_2.webp'
import img_3 from '../image/Works/img_2_1.webp'
import img_4 from '../image/Works/img_2_2.webp'
import img_5 from '../image/Works/img_3_1.webp'
import img_6 from '../image/Works/img_3_2.webp'
import img_7 from '../image/Works/img_4_1.webp'
import img_8 from '../image/Works/img_4_2.webp'
import img_9 from '../image/Works/img_5_1.webp'
import img_10 from '../image/Works/img_5_2.webp'
import img_11 from '../image/Works/img_6_1.webp'
import img_12 from '../image/Works/img_6_2.webp'
import img_13 from '../image/Works/img_7_1.webp'
import img_14 from '../image/Works/img_7_2.webp'
import img_15 from '../image/Works/img_8_1.webp'
import img_16 from '../image/Works/img_8_2.webp'
import img_17 from '../image/Works/img_9_1.webp'
import img_18 from '../image/Works/img_9_2.webp'
import img_19 from '../image/Works/img_10_1.webp'
import img_20 from '../image/Works/img_10_2.webp'
import img_21 from '../image/Works/img_12_1.webp'
import img_22 from '../image/Works/img_12_2.webp'
import img_23 from '../image/Works/img_11_1.webp'
import img_24 from '../image/Works/img_11_2.webp'

export const arrAdvantages = [{text:'Оперативный ВЫЕЗД'}, {text:'Работаем без ВЫХОДНЫХ'}, {text:'Сейчас дешевле на 15%'}, {text:'БЕСПЛАТНАЯ! консультация'}, {text:'Персональный ПОДХОД'}, {text:'Гарантия КАЧЕСТВА'},];

export const arrSeptic = [{text:'обслуживаем септики'}, {text:'ТОПАС'}, {text:'ЕВРОЛОС'}, {text:'ЮНИЛОС  АСТРА'}, {text:'БИОДЕКА'}, {text:'ЕВРОБИОН'}, {text:'ТВЕРЬ'}, {text:'ГРИНЛОС  АЭРО'}, {text:'КОЛО  ВЕСИ'}, {text:'ЭРГОБОКС'}, {text:'БИОФОР'}, ];


export const arrQuestions = [{question: 'Сервисное обслуживание'}, {question: 'Pемонт септика'}, {question: 'Дополнительные услуги'}, {question: 'Стоимость комплектующих'}, ]


export const arrOptionally = [{title: 'Перезапуск (полное ТО)', price: '+1 500'}, {title: 'Установка аварийной сигнализации', price: '3 000'}, {title: 'Обслуживание моделей лонг', price: '+1 000'}, ]

export const arrRepair = [{title: 'Замена поплавка', price: '1 200'}, {title: 'Замена мембран компрессора, чистка фильтров', price: '1 000'}, {title: 'Замена влагостойкой розетки', price: '400'}, {title: 'Замена электромагнитного клапана', price: '1 800'}, {title: 'Замена блока управления', price: '2 800'}, {title: 'Замена дренажного насоса', price: '700'}, {title: 'Замена компрессора', price: '600'}, {title: 'Замена аэрационных элементов', price: '1 000'}, {title: 'Замена блока форсунок', price: '1 700'}, {title: 'Врезка и опайка патрубка', price: '2 500'}, {title: 'Наращивание горловины', price: '4 900'}, {title: 'Проварка или восстановление герметичных швов станции', price: '1 000'}, {title: 'Замена термоусадочного соединения', price: '1 000'}, {title: 'Другие работы', price: 'уточняйте'},]

export const arrServiceCon  =[{title: 'Модели 3-4-5', price: 'от 5 000'}, {title: 'Модели 6-7-8', price: 'от 5 500'}, {title: 'Модели 9-10', price: 'от 6 000'}, {title: 'Модели 12-15', price: 'от 7 000'}, ]

export const arrParts  =[{title: 'В связи с нестабильным курсом валют просьба уточнять цены у менеджера.'}]
export const arrServices = [arrServiceCon, arrRepair, arrOptionally, arrParts]

export const arrWorks = [{title: 'Обслуживание септика Юнилос Астра-4', img: img_21, image: img_22,}, {title: 'Обслуживание септика Топас-6', img: img_19, image: img_20,}, {title: 'Обслуживание септика Топас-8', img: img_15, image: img_16,}, {title: 'Обслуживание септика Аквабокс-5', img: img_1, image: img_2,}, {title: 'Обслуживание септика Топас-6ПР', img: img_3, image: img_4,}, {title: 'Обслуживание септика Аквалос-2', img: img_5, image: img_6,}, {title: 'Обслуживание септика Топас-5', img: img_7, image: img_8,}, {title: 'Обслуживание септика Топас-5ПР', img: img_9, image: img_10,}, {title: 'Обслуживание септика Тверь-0,75П', img: img_11, image: img_12,}, {title: 'Обслуживание септика Тверь-1П', img: img_13, image: img_14,},  {title: 'Обслуживание септика Эко-Гранд 8', img: img_17, image: img_18,}, {title: 'Обслуживание септика Евротанк 5 лонг', img: img_23, image: img_24,},]
