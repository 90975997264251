import React from 'react'
import styles from './CardImage.module.scss'


export const CardImage = ({item, isImageOpen}) => {

    return (
        <li className={styles.item}>
        <h3 className={styles.item_title}>{item.title}</h3>
        <div className={styles.item_box}>
            <div className={styles.box_image}>
                <img className={styles.image} src={item.img} alt='септик'/>
                <h3 className={styles.subtitle}>до</h3>
            </div>
            <div className={styles.box_image}>
                <img className={styles.image} src={item.image} alt='септик'/>
                <h3 className={styles.subtitle_green}>после</h3>
            </div>
        </div>
    </li>
    )
}